<template>
  <div class="home22">
    其他页面
  </div>
</template>

<script>

export default {
  name: "algorithm-some",
  data(){
   return {
     value:''
   } 
  },
  components: {
  }
};
</script>

<style lang="scss" scoped>

</style>
